export const RouteType = {
    FILE: '/file',

    MEMBERSHIP_REGISTER: '/membership-register',
    EVENT_REGISTRATION: '/event-register',
    CONTACT_ENQUIRY: '/contact-enquiry',
    FEATURED_IN_NEWSLETTERS:'/featured-in-newsletter',
    NEWSLETTERS_SUBSCRIBERS:'/newsletter-subscribers',

    GET_COUNTRY: '/get-country',
    GET_STATE: '/get-state',
    GET_CITY: '/get-city',

    GET_EVENT: '/get-event',
    COMPANY_SERVICES: "/company-services",
    ADMIN: "/admin",
}

