import { Validation } from "constants/Validation";
import * as Yup from "yup";

const MembershipFormSchema = [

    Yup.object().shape({
        companyName: Yup.string().required(Validation.required.message.replaceAll("^", "Company Name"))
            .matches(Validation.plaintext.regex, Validation.plaintext.message),
        companyEmail: Yup.string().required(Validation.required.message.replaceAll("^", "Company Email"))
            .matches(Validation.email.regex, Validation.email.message),
        primaryPhoneNumber: Yup.string().required(Validation.required.message.replaceAll("^", "Phone Number"))
            .matches(Validation.mobile.regex, Validation.mobile.message)
            .min(10, Validation.minlength.message.replaceAll("^", 10))
            .max(15, Validation.maxlength.message.replaceAll("^", 15)),
        secondaryPhoneNumber: Yup.string()
            .matches(Validation.mobile.regex, Validation.mobile.message)
            .min(10, Validation.minlength.message.replaceAll("^", 10))
            .max(15, Validation.maxlength.message.replaceAll("^", 15)),
        websiteUrl: Yup.string().matches(Validation.website.regex, Validation.website.message),
        accountingEmail: Yup.string().matches(Validation.email.regex, Validation.email.message),
        companyLogo: Yup.string()
        //.required(Validation.required.message.replaceAll("^", "Company Logo"))
    }),
    Yup.object().shape({
        addressLine1: Yup.string().required(Validation.required.message.replaceAll("^", "Address 1"))
            .matches(Validation.plaintext.regex, Validation.plaintext.message),
        addressLine2: Yup.string().required(Validation.required.message.replaceAll("^", "Address 2"))
            .matches(Validation.plaintext.regex, Validation.plaintext.message),
        cityId: Yup.number(),
        //.required(Validation.required.message.replaceAll("^", "City")),
        stateId: Yup.number(),
        //.required(Validation.required.message.replaceAll("^", "State")),
        countryId: Yup.number().required(Validation.required.message.replaceAll("^", "Country")),
        zipCode: Yup.string().required(Validation.required.message.replaceAll("^", "Zip Code"))
            .matches(Validation.zipCode.regex, Validation.zipCode.message),
    }),
    Yup.object().shape({
        companyDetails: Yup.string().required(Validation.required.message.replaceAll("^", "Company Description"))
            .matches(Validation.plaintext.regex, Validation.plaintext.message),
    }),
    Yup.object().shape({
        instagramUrl: Yup.string().matches(Validation.website.regex, Validation.website.message),
        linkedinUrl: Yup.string().matches(Validation.website.regex, Validation.website.message),
        twitterUrl: Yup.string().matches(Validation.website.regex, Validation.website.message),
        facebookUrl: Yup.string().matches(Validation.website.regex, Validation.website.message),
        youtubeUrl: Yup.string().matches(Validation.website.regex, Validation.website.message)
    }),
    Yup.object().shape({
        membershipType: Yup.string().required(Validation.required.message.replaceAll("^", "Membership Type")),
    }),
    Yup.object().shape({
        contactPerson: Yup.array()
    }),
];

export default MembershipFormSchema;