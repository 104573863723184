import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, useFormikContext, Field } from 'formik';
import RECAPTCHA from "react-google-recaptcha";
import FormikControl from 'components/wrapperComponents/FormikControl'; // Adjust the path based on your project structure
import {
    Box,
    Grid,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Button,
    InputLabel,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { MembershipFormModel } from 'model/screensModels/membershipRegistration/MembershipFormModel';
import MembershipFormSchema from "schemas/membershipForm/MembershipFormSchema";
import { MembershipService } from 'services/menu/membership/MembershipService';
import { msgTypes } from 'constants/msgTypes';
import { UtilService } from 'services/shared/UtilService';
import MembershipContactPerson from './MembershipContactPerson';
import { UploadFileService } from 'services/shared/UploadFileService';
import { CountryFlag } from 'screens/sharedScreen/CountryFlag';



const MembershipForm = () => {
    const [captchaMatched, setCaptchaMatched] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [initialValues, setInitialValues] = useState(new MembershipFormModel());
    const [memberlist, setMemberList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [companyServiceList, setCompanyServiceList] = useState([]);
    const recaptcha = useRef();

    const validation = MembershipFormSchema

    useEffect(() => {
        loadCountry();
        getCompanyServiceList();
    }, [])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //load country
    const loadCountry = async () => {
        const res = await UtilService.getCountryList();
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setCountryList(res.data);
        }
    }

    //load state list
    const loadStateList = async (countryId) => {
        const res = await UtilService.getStateList(countryId);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setStateList(res.data);
        }
    }

    //load City list
    const loadCityList = async (stateId) => {
        const res = await UtilService.getCityList(stateId);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setCityList(res.data);
        }
    }

    const getCompanyServiceList = async () => {
        const res = await UtilService.getCompanyServiceList();
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            if (res.data !== '') {
                setCompanyServiceList(res.data)
            }
        }

    }

    const onCaptchaSubmit = (value) => {
        if (value)
            setCaptchaMatched(true)
        else
            setCaptchaMatched(false)
    }

    const prepareRequestBody = async (values) => {
        const prepareData = new MembershipFormModel();

        memberlist.map(data => {
            prepareData.contactPerson.push(data);
        })
        //  prepareData.contactPerson = memberlist ? values.contactPerson: [];
        prepareData.companyName = values.companyName ? values.companyName : "";
        prepareData.companyEmail = values.companyEmail ? values.companyEmail : "";
        prepareData.primaryPhoneNumber = values.primaryPhoneNumber ? values.primaryPhoneNumber : "";
        prepareData.secondaryPhoneNumber = values.secondaryPhoneNumber ? values.secondaryPhoneNumber : "";
        prepareData.websiteUrl = values.websiteUrl ? values.websiteUrl : "";
        prepareData.accountingEmail = values.accountingEmail ? values.accountingEmail : "";
        prepareData.addressLine1 = values.addressLine1 ? values.addressLine1 : "";
        prepareData.addressLine2 = values.addressLine2 ? values.addressLine2 : "";
        prepareData.cityId = values.cityId ? values.cityId : 0;
        prepareData.stateId = values.stateId ? values.stateId : 0;
        prepareData.countryId = values.countryId ? values.countryId : 0;
        prepareData.zipCode = values.zipCode ? values.zipCode : "";
        prepareData.companyDetails = values.companyDetails ? values.companyDetails : "";
        prepareData.instagramUrl = values.instagramUrl ? values.instagramUrl : "";
        prepareData.linkedinUrl = values.linkedinUrl ? values.linkedinUrl : "";
        prepareData.twitterUrl = values.twitterUrl ? values.twitterUrl : "";
        prepareData.facebookUrl = values.facebookUrl ? values.facebookUrl : "";
        prepareData.youtubeUrl = values.youtubeUrl ? values.youtubeUrl : "";
        prepareData.membershipType = values.membershipType ? values.membershipType : "";
        prepareData.companyLogo = values.companyLogo ? values.companyLogo : "";
        //to get image url
        // if (values.companyLogo) {
        //     const formData = new FormData();
        //     formData.append("file", values.companyLogo);
        //     const imageurlData = await UploadFileService.getImagePath(formData);
        //     prepareData.companyLogo = imageurlData.data.fileName;
        // } else {
        //     prepareData.companyLogo = '';
        // }
        prepareData.companyServices = values.companyServices ? values.companyServices : [];
        return prepareData
    }

    const submitMembership = async (values) => {
        const requestBody = await prepareRequestBody(values);
        const res = await MembershipService.addMembership(requestBody)
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setActiveStep(0);
            setMemberList([]);
            return true
        }
        return false;
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>

            <section className="page-header member-section">
                <div className="page-header__bg membersship-banner">
                </div>
                <div className="container membership-container">
                    <div className="page-header__inner">
                        <h2>Membership Registration</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Membership Registration</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Brand One Start--> */}
            <CountryFlag />
            {/* <!--Brand One End--> */}

            <Box className="membership-wrapper" id="membership" width="100%" height="100%" margin="0 auto" padding="0px">
                <Typography variant="h4" align="center" marginBottom="20px">
                    Membership Form
                </Typography>
                <Stepper
                    activeStep={activeStep}
                    orientation={isSmallScreen ? 'vertical' : 'horizontal'}
                    sx={{
                        width: '80%',
                        backgroundColor: 'transparent',
                        marginBottom: '13px',
                        fontWeight: '300',
                        margin: '5 auto',
                        fontSize: '1.5rem' // Adjust the font size for larger labels
                    }}
                >
                    {[
                        'Company Details',
                        'Company Address',
                        'Company Description',
                        'Company Social Profiles',
                        'Membership Type',
                        'Contact Person',
                    ].map((label, index) => (
                        <Step key={index}>
                            <StepLabel
                                sx={{
                                    '& .MuiStepLabel-label': {
                                        fontSize: '1.0rem', // Adjust the font size for larger labels
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase'
                                    }
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box className="main-box membership-form-tab" width="80%" margin="0 auto" padding="20px" marginBottom='60px'>
                    <Grid container justifyContent="left">
                        <Paper
                            className="event-paper"
                            style={{
                                // padding: '20px',
                                marginBottom: '20px',
                                backgroundColor: '#ffffff'
                            }}
                        >
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validation[activeStep]}
                                onSubmit={async (values, { resetForm }) => {
                                    if (activeStep < validation.length - 1) {
                                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                    }
                                    const res = await submitMembership(values);
                                    if (res)
                                        resetForm();
                                }}
                            >
                                {({ handleSubmit, isSubmitting, isValid, dirty, values, formik, setFieldValue, setFieldTouched }) => (
                                    <Form onSubmit={handleSubmit}>

                                        {activeStep === 0 && (
                                            <>
                                                <div className='member-block-body'>
                                                    <Typography variant="h4" align="center" marginBottom="30px">
                                                        Company Details
                                                    </Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="companyName"
                                                                className='form-control'
                                                                label="Company Name"
                                                                placeholder="Enter Your Company Name"
                                                                requiredField
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="companyEmail"
                                                                className='form-control'
                                                                label="Company Email"
                                                                placeholder="Enter Your Company Email"
                                                                requiredField
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="primaryPhoneNumber"
                                                                className='form-control'
                                                                label="Mobile Number"
                                                                placeholder="Enter Your Mobile Number"
                                                                requiredField
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="secondaryPhoneNumber"
                                                                className='form-control'
                                                                label="Alternative Mobile Number"
                                                                placeholder="Enter Your Alternative Mobile Number"

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="websiteUrl"
                                                                className='form-control'
                                                                label="URL"
                                                                placeholder="Enter Your URL"

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="accountingEmail"
                                                                className='form-control'
                                                                label="Accounting Email"
                                                                placeholder="Enter Your Accounting Email"

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <FormikControl
                                                                control="uploadImage"
                                                                className='form-control file-input'
                                                                name="companyLogoFile"
                                                                nameUrl="companyLogo"
                                                                label="Company Logo"
                                                                // placeholder="Enter Your Email Id"
                                                                // requiredField
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Box marginTop="20px" className="next-btn active-1" display="flex" justifyContent="space-between">
                                                        <Button
                                                            //type="submit"
                                                            className='btn btn-submit'
                                                            onClick={handleNext}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={!dirty || !(isValid)}
                                                        >
                                                            Next
                                                        </Button>
                                                    </Box>
                                                </div>
                                            </>
                                        )}
                                        {activeStep === 1 && (
                                            <>
                                                <div className='member-block-body'>
                                                    <Typography variant="h4" align="center" marginBottom="30px">
                                                        Company Address
                                                    </Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="addressLine1"
                                                                className='form-control'
                                                                label="Address Line 1"
                                                                placeholder="Enter Your Address"
                                                                requiredField
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="addressLine2"
                                                                className='form-control'
                                                                label="Address Line 2"
                                                                placeholder="Enter Your Address"
                                                                requiredField
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl control='autocomplete'
                                                                label="Country"
                                                                name="countryId"
                                                                placeholder="Select Country"
                                                                className='form-control selected-cat'
                                                                requiredField={true}
                                                                options={countryList}
                                                                onChange={(e) => { loadStateList({ "country": e.countryIdentifier }) }}
                                                                keyfield="name"
                                                                valuefield="countryIdentifier"
                                                            />


                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl control='autocomplete'
                                                                label="State"
                                                                name="stateId"
                                                                placeholder="Select State"
                                                                className='form-control selected-cat'
                                                               // requiredField={true}
                                                                options={stateList}
                                                                onChange={(e) => { loadCityList({ "state": e.stateIdentifier }) }}
                                                                keyfield="name"
                                                                valuefield="stateIdentifier"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl control='autocomplete'
                                                                label="City"
                                                                name="cityId"
                                                                placeholder="Select City"
                                                                className='form-control selected-cat'
                                                               // requiredField={true}
                                                                options={cityList}
                                                                onChange={(e) => { loadCityList({ "state": e.stateIdentifier }) }}
                                                                keyfield="name"
                                                                valuefield="stateIdentifier"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="zipCode"
                                                                className='form-control'
                                                                label="Zip Code"
                                                                placeholder="Enter Your Company Zip Code"
                                                                requiredField
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Box className='next-btn' marginTop="20px" display="flex" justifyContent="space-between">
                                                        <Button
                                                            className='btn btn-back'
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button
                                                            //type="submit"
                                                            className='btn btn-submit'
                                                            onClick={handleNext}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={!dirty || !isValid || values.addressLine1.length === 0}
                                                        >
                                                            Next
                                                        </Button>
                                                    </Box>
                                                </div>
                                            </>
                                        )}
                                        {activeStep === 2 && (
                                            <>
                                                <div className='member-block-body'>
                                                    <Typography variant="h4" align="center" marginBottom="30px">
                                                        Company Description
                                                    </Typography>
                                                    <Grid container className="text-area mb-4" item xs={12}>
                                                        <FormikControl
                                                            control="textfield"
                                                            label="Company Details"
                                                            className='textarea form-control'
                                                            type="text"
                                                            variant="outlined"
                                                            requiredField
                                                            multiline
                                                            rows={4}
                                                            fullWidth
                                                            sx={{ m: 1 }}
                                                            name="companyDetails"
                                                            placeholder="Company Details"
                                                        />
                                                    </Grid>

                                                    <Grid className="pl-8 m-4" item xs={12}>
                                                        <InputLabel
                                                            variant="standard"
                                                            sx={{ textAlign: "left", fontWeight: "500", color: "#000000" }}>
                                                            Company Services
                                                        </InputLabel>
                                                        <div role="group" aria-labelledby="checkbox-group" className='m-2 position-relative '>
                                                            {companyServiceList.map(data => {
                                                                return (
                                                                    <div>
                                                                        <InputLabel
                                                                            variant="standard"
                                                                            sx={{ textAlign: "left", fontWeight: "500", color: "#000000" }}>
                                                                            <Field type="checkbox" name="companyServices" value={"" + data.serviceId} />&nbsp;&nbsp;{data.serviceName}
                                                                        </InputLabel>
                                                                    </div>
                                                        )
                                                        })}
                                                </div>
                                            </Grid>


                                        <Box className='next-btn' marginTop="20px" display="flex" justifyContent="space-between">
                                            <Button
                                                className='btn btn-back'
                                                variant="contained"
                                                color="primary"
                                                onClick={handleBack}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                className='btn btn-submit'
                                                onClick={handleNext}
                                                //type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={!dirty || !(isValid) || values.companyDetails.length === 0}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </div>
                                            </>
                                        )}
                            {activeStep === 3 && (
                                <> <div className='member-block-body'>
                                    <Typography variant="h4" align="center" marginBottom="30px">
                                        Company Social Profiles
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormikControl
                                                control="textfield"
                                                name="instagramUrl"
                                                className='form-control'
                                                label="Instagram URL"
                                                placeholder="Enter Your Instagram URL"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormikControl
                                                control="textfield"
                                                name="linkedinUrl"
                                                className='form-control'
                                                label="LinkedIn URL"
                                                placeholder="Enter Your LinkedIn URL"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormikControl
                                                control="textfield"
                                                name="twitterUrl"
                                                className='form-control'
                                                label="Twitter URL"
                                                placeholder="Enter Your Twitter URL"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormikControl
                                                control="textfield"
                                                name="facebookUrl"
                                                className='form-control'
                                                label="Facebook URL"
                                                placeholder="Enter Your Facebook URL"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormikControl
                                                control="textfield"
                                                name="youtube"
                                                label="YouTube URL"
                                                className='form-control'
                                                placeholder="Enter Your YouTube URL"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box className='next-btn' marginTop="20px" display="flex" justifyContent="space-between">
                                        <Button
                                            className='btn btn-back'
                                            variant="contained"
                                            color="primary"
                                            onClick={handleBack}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            // type="submit"
                                            className='btn btn-submit'
                                            onClick={handleNext}
                                            variant="contained"
                                            color="primary"
                                            disabled={!dirty || !(isValid)}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </div>
                                </>
                            )}
                            {activeStep === 4 && (
                                <>
                                    <div className='member-block-body'>
                                        <Typography variant="h4" align="center" marginBottom="30px">
                                            Membership Type
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <FormikControl
                                                    className="radiobutton"
                                                    control="radiogroup"
                                                    label="Membership Type"
                                                    name="membershipType"
                                                    options={[
                                                        { key: "Founder Member", value: "founder" },
                                                        { key: "Exclusive Member", value: "exclusive" },
                                                    ]}
                                                    placeholder="Select Membership Type"
                                                    requiredField
                                                    row
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box className="member-btn-one next-btn" marginTop="20px" display="flex" justifyContent="space-between">
                                            <Button
                                                className='btn btn-back'
                                                variant="contained"
                                                color="primary"
                                                onClick={handleBack}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                //type="submit"
                                                className='btn btn-submit'
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                disabled={(!dirty || !(isValid)) || values.membershipType.length === 0}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </div>
                                </>
                            )}
                            {activeStep === 5 && (
                                <>
                                    <div className='member-block-body hgfgh'>
                                        <Grid container spacing={2}>
                                            <MembershipContactPerson memberlist={memberlist} setMemberList={setMemberList} />
                                        </Grid>

                                        {memberlist?.length > 0 ?
                                            <>
                                                <RECAPTCHA
                                                    sitekey={window.location.href.includes(msgTypes.LOCALHOST) ? msgTypes.GOOGLE_CAPTCHA_SITE_KEY_LOCAL : msgTypes.GOOGLE_CAPTCHA_SITE_KEY_PROD}
                                                    ref={recaptcha}
                                                    onChange={onCaptchaSubmit}
                                                    className='recaptcha-obj'
                                                />

                                                <Box className="btn-member btn-position" marginTop="20px" display="flex" justifyContent="space-between">
                                                    <Button
                                                        className='btn btn-back'
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleBack}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        className='btn btn-submit'
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting && (!dirty || !(isValid) || memberlist.length === 0) || !captchaMatched}
                                                    >
                                                        Submit
                                                    </Button>

                                                </Box>
                                            </>

                                            : ""}
                                    </div>
                                </>
                            )}
                        </Form>
                                )}
                    </Formik>
                </Paper>
            </Grid>
        </Box >
            </Box >
        </>
    );
};

export default MembershipForm;
